import React, {useEffect} from 'react';

import HomeHeader from './HomeHeader';
import StockRatingTable from './StockRatingTable';
import FooterComponent from "./FooterComponent";

const AristocratsRatingComponent = () => {

    useEffect(() => {
        document.title = "Portfoliomanger - рейтинг дивидендных аристократов США"
    }, [])
    
    return (<div>
        <HomeHeader/>
        <StockRatingTable/>
        <FooterComponent/>
    </div>)
}

export default AristocratsRatingComponent;