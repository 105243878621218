import React, {Component, PureComponent} from 'react';
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import HomeHeader from "./HomeHeader";
import StockDataApi from "../api/StockDataApi";
import {LoaderComponent, withLoader} from "./LoaderComponent";
import ErrorComponent from "./ErrorComponent";
import FooterComponent from "./FooterComponent";

//exception handling in backend/frontend NoDataFoundException, RuntimeException (stock is not available for example)
//cross origin policy fix
//запуск джобов по расписанию
//fix all warnings and remove all console.log

//refactoring of data loading and error handling to make it more general
//счетчик запросов в AV
//-----------------------------------
//улучшить кеш
//график цены
//see iexcloud.io
//https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
//https://stackoverflow.com/questions/40987309/react-display-loading-screen-while-dom-is-rendering
//https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d

const fieldsListToScale = {
    incomeStatement: ["totalRevenue", "netIncome"],
    balanceSheet_1: ["longTermDebt", "cashAndShortTermInvestments"],
    balanceSheet_2: ["commonStockSharesOutstanding"]
}

const sectorsMap = {
    "finance": "Финансы",
    "technology": "Технологии",
    "energy & transportation": "Энергетика/транспорт",
    "life sciences": "Естественные науки",
    "manufacturing": "Промышленность/производство",
    "real estate & construction": "Недвижимость/строительство",
    "trade & services": "Продажи/услуги"
}

const BILLION = 1000000000
const MILLION = 1000000
const HUNDRED = 100
const NA = "N/A"
const NONE = "None"

let fieldGroupScale = {
    incomeStatement: BILLION,
    balanceSheet_1: BILLION,
    balanceSheet_2: 0
}

//https://recharts.org/en-US/examples/CustomContentOfTooltip
class StockDataPage extends PureComponent {
    state = {
        stockData: {
            currentPrice: "",
            quarterlyReports: [],
            annualReports: [],
            overview: {
                Sector: ""
            }
        },
        displayAnnualReport: {
            earningAndProfit: true,
            cashAndDebt: true,
            outstandingShares: true
        }
    }

    constructor(props) {
        super(props);
        const stockData = this.props.data
        if (stockData.quarterlyReports && stockData.annualReports) {
            this.scaleData(stockData.quarterlyReports)
            this.scaleData(stockData.annualReports)
            const displayAnnualReport = {
                earningAndProfit: true,
                cashAndDebt: true,
                outstandingShares: true
            }
            this.state = {stockData: stockData, displayAnnualReport: displayAnnualReport}
        }
    }

    scaleData = (reports) => {
        reports.map(
            (report) => {
                Object.keys(fieldsListToScale).forEach(
                    (key) => {
                        fieldsListToScale[key].forEach(field => {
                            const [group, index] = key.split('_')
                            if (fieldGroupScale[key] == 0) {
                                if (report[group][field] / BILLION > 1) {
                                    fieldGroupScale[key] = BILLION
                                } else {
                                    fieldGroupScale[key] = MILLION
                                }
                            }
                            report[group][field] = report[group][field] / fieldGroupScale[key]
                        })
                    }
                )
            }
        );
    }

    formatYAxis = (tickItem, group) => {
        if (fieldGroupScale[group] == BILLION) {
            return tickItem + " млрд."
        } else {
            return tickItem + " млн."
        }
    }

    handleInputChange = (event) => {
        let newValue = !this.state.displayAnnualReport[event.target.id]
        const displayAnnualReport = {...this.state.displayAnnualReport, [event.target.id]: newValue}
        this.setState(() => ({displayAnnualReport}))
    }

    getSector = () => {
        if (sectorsMap[this.state.stockData.overview.Sector.toLowerCase()])
            return sectorsMap[this.state.stockData.overview.Sector.toLowerCase()]
        else return this.state.stockData.overview.Sector
    }

    render() {
        if (!this.state.stockData.annualReports.length) return <div></div>
        return (
            <div>
                <div className="row">
                    <div className="col-12 text-center">
                        <h2>{this.state.stockData.overview.Name}&nbsp;
                            <small className="text-muted">{this.state.stockData.symbol}&nbsp;
                                {this.state.stockData.currentPrice}$
                            </small></h2>
                        <h6>Сектор: {this.getSector()}</h6>
                        <h6>Индустрия: {this.state.stockData.overview.Industry}</h6>
                        <h6>Стоимость компании
                            - <b>{(this.state.stockData.overview.MarketCapitalization / BILLION).toFixed(2)} млрд.
                                $</b></h6>
                    </div>
                </div>
                <hr/>
                <div className="row justify-content-md-center">
                    <div className="col-sm-4">
                        <h6 className="text-center">Дивиденды</h6>
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>Дивидендная доходность</td>
                                <td><b>{(this.state.stockData.overview.DividendYield * HUNDRED).toFixed(2)}%</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Дивиденды на акцию</td>
                                <td><b>{this.state.stockData.overview.DividendPerShare}$</b></td>
                            </tr>
                            <tr>
                                <td>Payout ratio <small className="text-muted">Процент дивидендендов от
                                    прибыли</small></td>
                                <td><b>{(this.state.stockData.overview.PayoutRatio * HUNDRED).toFixed(1)}%</b></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-sm-4">
                        <h6 className="text-center">Оценка стоимости</h6>
                        <table className="table">
                            <tr>
                                <td>P/E<small className="text-muted">Цена акции/прибыль</small></td>
                                <td><b>{this.state.stockData.overview.PERatio}</b></td>
                            </tr>
                            <tr>
                                <td>P/S<small className="text-muted">Цена акции/выручка</small></td>
                                <td><b>{this.state.stockData.overview.PriceToSalesRatioTTM}</b></td>
                            </tr>
                            <tr>
                                <td>Forward P/E<small className="text-muted">Цена акции/прогнозируемая
                                    прибыль</small></td>
                                <td><b>{this.state.stockData.overview.ForwardPE}</b></td>
                            </tr>
                            <tr>
                                <td>P/B<small className="text-muted">Цена акции/балансовая стоимость</small></td>
                                <td><b>{this.state.stockData.overview.PriceToBookRatio}</b></td>
                            </tr>
                            <tr>
                                <td>Book value<small className="text-muted">Балансовая стоимость на акцию</small>
                                </td>
                                <td><b>{this.state.stockData.overview.BookValue}</b></td>
                            </tr>
                        </table>
                    </div>
                    <div className="col-sm-4">
                        <h6 className="text-center">Рентабельность</h6>
                        <table className="table">
                            <tr>
                                <td>ROA<small className="text-muted">Доходность активов</small></td>
                                <td><b>{(this.state.stockData.overview.ReturnOnAssetsTTM * HUNDRED).toFixed(1)}%</b>
                                </td>
                            </tr>
                            <tr>
                                <td>ROE<small className="text-muted">Доходность капитала</small></td>
                                <td><b>{(this.state.stockData.overview.ReturnOnEquityTTM * HUNDRED).toFixed(1)}%</b>
                                </td>
                            </tr>
                            <tr>
                                <td>Profit margin<small className="text-muted">Прибыль от выручки</small></td>
                                <td><b>{(this.state.stockData.overview.ProfitMargin * HUNDRED).toFixed(1)}%</b></td>
                            </tr>
                            <tr>
                                <td>Operating margin<small className="text-muted">Операционная прибыль от
                                    выручки</small></td>
                                <td>
                                    <b>{(this.state.stockData.overview.OperatingMarginTTM * HUNDRED).toFixed(1)}%</b>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <hr/>
                <div>
                    <div className="row justify-content-md-center">
                        <div className="col-sm-9">
                            <h6 className="text-center">История дивидендных выплат</h6>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <td>Период (год)</td>
                                        {this.state.stockData.annualReports.map(report =>
                                            <td>{new Date(report.cashFlow.fiscalDateEnding).getFullYear()}</td>)}</tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Дивидендная доходность</td>
                                        {this.state.stockData.annualReports.map(report =>
                                            <td>{report.dividendAmount == NONE ? NA : report.dividendAmount + "%"}</td>)}
                                    </tr>
                                    <tr>
                                        <td>Рост дивидендов</td>
                                        {this.state.stockData.annualReports.map(report =>
                                            <td>{report.dividendGrowth == NONE ? NA : report.dividendGrowth + "%"}</td>)}
                                    </tr>
                                    <tr>
                                        <td>Payout ratio</td>
                                        {this.state.stockData.annualReports.map(report =>
                                            <td>{report.payoutRatio == NONE ? NA : report.payoutRatio + "%"}</td>)}</tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h5 className="text-center">Выручка и прибыль <small className="text-muted">(доллары
                            США)</small></h5>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <div className="form-check form-switch mx-auto">
                            <input className="form-check-input" type="checkbox" id="earningAndProfit"
                                   onChange={this.handleInputChange}/>
                            <label className="form-check-label" htmlFor="earningAndProfit">Отчет по
                                годам</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ResponsiveContainer height={300}>
                            <BarChart
                                width={800}
                                height={300}
                                data={this.state.displayAnnualReport["earningAndProfit"] ? this.state.stockData.quarterlyReports : this.state.stockData.annualReports}
                                margin={{
                                    top: 30,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="incomeStatement.fiscalDateEnding"/>
                                <YAxis tickFormatter={tick => this.formatYAxis(tick, "incomeStatement")}/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="incomeStatement.totalRevenue" name="Выручка" fill="#8884d8"/>
                                <Bar dataKey="incomeStatement.netIncome" name="Прибыль" fill="#82ca9d"/>
                            </BarChart>

                        </ResponsiveContainer>

                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6"><h5 className="text-center">Долговые обязательства и денежные
                        средства <small className="text-muted">(доллары США)</small></h5>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="cashAndDebt"
                                   onChange={this.handleInputChange}/>
                            <label className="form-check-label" htmlFor="cashAndDebt">Отчет по
                                годам</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ResponsiveContainer height={300}>
                            <BarChart
                                width={800}
                                height={300}
                                data={this.state.displayAnnualReport["cashAndDebt"] ? this.state.stockData.quarterlyReports : this.state.stockData.annualReports}
                                margin={{
                                    top: 30,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="incomeStatement.fiscalDateEnding"/>
                                <YAxis tickFormatter={tick => this.formatYAxis(tick, "balanceSheet_1")}/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="balanceSheet.longTermDebt" name="Долговые обязательства"
                                     fill="#db4655"/>
                                <Bar dataKey="balanceSheet.cashAndShortTermInvestments"
                                     name="Денежные средства"
                                     fill="#82ca9d"/>
                            </BarChart>
                        </ResponsiveContainer>

                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <h5 className="text-center">Акций в обращении <small className="text-muted">(штуки)</small>
                        </h5>
                    </div>
                    <div className="col-md-3 d-flex justify-content-center">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="outstandingShares"
                                   onChange={this.handleInputChange}/>
                            <label className="form-check-label" htmlFor="outstandingShares">Отчет по
                                годам</label>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ResponsiveContainer height={300}>
                            <BarChart
                                width={800}
                                height={300}
                                data={this.state.displayAnnualReport["outstandingShares"] ? this.state.stockData.quarterlyReports : this.state.stockData.annualReports}
                                margin={{
                                    top: 30,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="incomeStatement.fiscalDateEnding"/>
                                <YAxis tickFormatter={tick => this.formatYAxis(tick, "balanceSheet_2")}/>
                                <Tooltip/>
                                <Legend/>
                                <Bar dataKey="balanceSheet.commonStockSharesOutstanding" name="Акций в обращении"
                                     fill="#8884d8"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>

        )
    }
}

const StockDataPageWithLoader = withLoader(StockDataPage, (props) => {
        //if (props.match.params) {
        return new StockDataApi().getStockData(props.match.params.stockSymbol)
        //}
        //return {}
    },
    (props, prevProps) => {
        if (props.match.params.stockSymbol !== prevProps.match.params.stockSymbol) return true
        return false
    });

export default class StockPageComponent extends Component {
    state = {
        error: null,
        loading: true
    }

    loaderHandler = (loading) => this.setState({loading: loading})
    errorHandler = (error) => this.setState({error: error})

    getErrorComponent() {
        return <ErrorComponent error={this.state.error}/>
    }

    getLoader() {
        return <LoaderComponent loading={this.state.loading}/>
    }

    render() {
        return (
            <div>
                <HomeHeader/>
                <div className="container mainComponentContainer">
                    {this.getErrorComponent()}
                    <div className="text-center">
                        {this.getLoader()}
                    </div>
                    <StockDataPageWithLoader loaderHandler={this.loaderHandler}
                                             errorHandler={this.errorHandler} {...this.props}/>
                </div>
                <FooterComponent/>
            </div>
        );
    }
}

