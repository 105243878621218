import React from 'react';

const FooterComponent = () => {
    return (<footer className="py-3 my-4">
            <ul className="nav justify-content-center border-bottom pb-3 mb-3">
                <li className="nav-item"><a href="/rating" className="nav-link px-2 text-muted">Рейтинг</a></li>
                <li className="nav-item"><a href="/calendar" className="nav-link px-2 text-muted">Календарь</a></li>
            </ul>
            <p className="text-center text-muted">© 2021 Portfolio manager</p>
            <p className="text-center text-muted">support@portfoliomanager.ru</p>
        </footer>
    )
}

export default FooterComponent;