import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import React from 'react'
import ReactDOM from 'react-dom'

import {BrowserRouter} from "react-router-dom"

import AppRouter from './AppRouter'

ReactDOM.render((
        <BrowserRouter>
            <AppRouter/>
        </BrowserRouter>
    ), document.getElementById('root')
);
