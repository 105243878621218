import React, {useState} from "react";
import SearchApi from "../api/SearchApi";
import {useHistory} from "react-router-dom";

//TODO refactor and make it beautiful
const AutoCompleteList = ({items, suggestionIndex}) => {
    let history = useHistory();

    const onClick = (e) => {
        history.push("/stock/" + e.target.getAttribute('symbol'));
    };

    if (items.length === 0) {
        return (<ul className="suggestions">
            <li>Нет такого тикера или компании</li>
        </ul>)
    } else {
        return (
            <ul className="suggestions">
                {items.map((item, index) => {
                        let className;
                        if (index === suggestionIndex) {
                            className = "suggestion-active";
                        }
                        return <li className={className} onClick={onClick} key={item.symbol}
                                   symbol={item.symbol}>{item.symbol} - {item.name}</li>
                    }
                )}
            </ul>
        );
    }
}

const AutoCompleteComponent = (props) => {
    let history = useHistory();

    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");

    const onChange = (e) => {
        const userInput = e.target.value;
        if (userInput) {
            new SearchApi().search(userInput).then(newSuggestions => {
                setInput(userInput);
                setSuggestions(newSuggestions.data);
                setActiveSuggestionIndex(-1);
                setShowSuggestions(true);
            })
        } else {
            setInput("");
            setSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
        }
    };

    const onKeyDown = (e) => {
        const userInput = e.target.value;
        if (e.keyCode === 13) {
            suggestions.map((item, index) => {
                if (index === activeSuggestionIndex)
                    setInput("")
                    setSuggestions([])
                    setActiveSuggestionIndex(0)
                    setShowSuggestions(false)
                    history.push("/stock/" + item.symbol)
            })
        }
        if (e.keyCode === 40) {
            if (activeSuggestionIndex < suggestions.length - 1) {
                setActiveSuggestionIndex(activeSuggestionIndex + 1)
            }
            if (!showSuggestions) {
                new SearchApi().search(userInput).then(newSuggestions => {
                    setInput(userInput);
                    setSuggestions(newSuggestions.data);
                    setActiveSuggestionIndex(-1);
                    setShowSuggestions(true);
                })
            }
        }
        if (e.keyCode === 38) {
            if (activeSuggestionIndex > 0) {
                setActiveSuggestionIndex(activeSuggestionIndex - 1)
            } else {
                setInput("");
                setSuggestions([]);
                setActiveSuggestionIndex(0);
                setShowSuggestions(false);
            }
        }
    }

    return (
        <div className={props.divClass}>
            <input type="email" className="form-control" id="floatingInput" autoComplete="off"
                   placeholder={props.placeholder} onChange={onChange} onKeyDown={onKeyDown}/>
            {(() => {
                if (props.label) {
                    return <label htmlFor="floatingInput">{props.label}</label>
                }
            })()}
            {showSuggestions && input &&
            <AutoCompleteList items={suggestions} suggestionIndex={activeSuggestionIndex}/>}
        </div>
    );
}

export default AutoCompleteComponent;