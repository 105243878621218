import React, {Component} from "react";

export default class ErrorComponent extends Component {
    render() {
        let errorMessage = ""
        if(this.props.error) {
            console.log(this.props.error)
            if (this.props.error.response) {
                errorMessage = "На сервере произошла неожиданная ошибка. Попробуйте позже или свяжитесь с технической поддержкой"
            } else if (this.props.error.request) {
                errorMessage = "Сервер не доступен"
            } else {
                errorMessage = "У нас что-то случилось и данные не доступны, свяжитесь с технической поддержкой"
            }
        }

        return (errorMessage &&
            <div className="alert alert-danger" role="alert">{errorMessage}</div>);
    }
}