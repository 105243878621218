import React, {Component} from 'react';
import satisfied_icon from "./static/yes.png"
import not_satisfied_icon from "./static/no.png"
import {LoaderComponent, withLoader} from "./LoaderComponent";
import StockRatingApi from "../api/StockRatingApi";
import ErrorComponent from "./ErrorComponent";
import './static/custom.css'

class StockRatingDataTable extends Component {
    state = {
        stocks: []
    };

    constructor(props) {
        super(props);
        this.state = {stocks: this.props.data}
    }

    render() {
        return this.state.stocks.map((stock) =>
            <tr key={stock.symbol}>
                <td className="text-center">{stock.symbol}</td>
                <td className="text-center">{stock.stockName}</td>
                <td className="text-center"><img
                    src={stock.totalRevenueCheck ? satisfied_icon : not_satisfied_icon} width={20}/>
                </td>
                <td className="text-center"><img
                    src={stock.netIncomeCheck ? satisfied_icon : not_satisfied_icon} width={20}/>
                </td>
                <td className="text-center"><img
                    src={stock.payoutRatioCheck ? satisfied_icon : not_satisfied_icon} width={20}/>
                </td>
                <td className="text-center"><img
                    src={stock.longTermDebtCheck ? satisfied_icon : not_satisfied_icon} width={20}/>
                </td>
                <td className="text-center"><img
                    src={stock.commonStockSharesOutstandingCheck ? satisfied_icon : not_satisfied_icon}
                    width={20}/></td>
                <td className="text-center"><img
                    src={stock.bookValueCheck ? satisfied_icon : not_satisfied_icon} width={20}/>
                </td>
                <td className="text-center">{stock.dividendYield}</td>
                <td className="text-center">
                    <a href={"stock/" + stock.symbol} target="_blank" className="btn btn-secondary">Детали</a>
                </td>
            </tr>
        )
    }
}

const StockRatingTableWithLoader = withLoader(StockRatingDataTable, () => {
    return new StockRatingApi().getDividendAristocrats()
});

//TODO extract general part
export default class StockRatingTable extends Component {
    state = {
        error: null,
        loading: true
    };

    loaderHandler = (loading) => this.setState({loading: loading})
    errorHandler = (error) => this.setState({error: error})

    getErrorComponent() {
        return <ErrorComponent error={this.state.error}/>
    }

    getLoader() {
        return <LoaderComponent loading={this.state.loading}/>
    }

    render() {
        return (
            <div className="container mainComponentContainer">
                {this.getErrorComponent()}
                <div className="alert alert-info" role="alert">
                    Не является индивидуальной инвестицинной рекомендацией!
                </div>
                <h2 className="text-center">Рейтинг дивидендных аристократов США</h2>

                <div className="table-responsive">
                    <table className="table">
                        <thead>
                        <tr style={{
                            position: 'sticky', top: '0', 'zIndex': '1', background: '#ffffff',
                            'boxShadow': 'inset 0 -1px 0 #000000'
                        }}>
                            <th scope="col" className="text-center">Тикер</th>
                            <th scope="col" className="text-center">Название</th>
                            <th scope="col" className="text-center">Рост выручки</th>
                            <th scope="col" className="text-center">Рост прибыли</th>
                            <th scope="col" className="text-center">Процент дивидендов от прибыли &lt; 95%</th>
                            <th scope="col" className="text-center">Снижение долговых обязательств</th>
                            <th scope="col" className="text-center">Обратный выкуп</th>
                            <th scope="col" className="text-center">Рост собственного капитала</th>
                            <th scope="col" className="text-center">Дивидендная доходность</th>
                            <th scope="col" className="text-center">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        <StockRatingTableWithLoader loaderHandler={this.loaderHandler} errorHandler={this.errorHandler}/>
                        </tbody>
                    </table>
                </div>



                <div className="text-center">
                    {this.getLoader()}
                </div>
            </div>
        );
    }
};
