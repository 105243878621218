import React, {useEffect} from 'react';
import './static/custom.css'
import AutoCompleteComponent from "./AutoCompleteComponent";
import FooterComponent from "./FooterComponent";
import rating_img from "./static/rating.jpg"
import calendar_img from "./static/calendar.jpg"

const HomeComponent = () => {


    useEffect(() => {
        document.title = "Portfoliomanger - платформа для долгосрочных инвесторов фондового рынка"
    }, [])

    return (<main>
        <header>
            <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                <div className="container" >
                    <div className="navbar-brand">PM</div>
                </div>
            </nav>
        </header>
        <div className="bg-dark text-secondary px-4 py-1 text-center">
            <div className="py-2">
                <h1 className="display-5 fw-bold text-white">Portfolio manager</h1>
                <div className="col-lg-6 mx-auto">
                    <p className="fs-5 mb-4">Платформа для долгосрочных инвесторов</p>
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-6 col-sm-8 text-start">
                            <ul className="">
                                <li>Более 2000 акций США</li>
                                <li>Все фундаментальные показатели на одной странице</li>
                                <li>История и календарь дивидендов</li>
                                <li>Рейтинг дивидендных аристократов</li>
                            </ul>
                        </div>
                    </div>
                    <form className="p-2 p-md-3">
                        <AutoCompleteComponent label = "Тикер или название компании" divClass = "form-floating mb-3" placeholder = "AAPL"></AutoCompleteComponent>
                    </form>
                </div>
            </div>
        </div>
        <div className="container py-3">
            <div className="row g-3">
                <div className="col-md-4">
                    <div className="card">
                        <img src={rating_img} className="card-img-top"  />
                            <div className="card-body">
                                <h5 className="card-title">Рейтинг дивидендных аристократов США</h5>
                                <p className="card-text">Самый простой способ подобрать надежные и доходные акции в долгосрочный портфель*<br/>
                                    <small className="text-muted">*Не является индивидуальной инвестицинной рекомендацией</small>
                                </p>
                                <a href="/rating" className="btn btn-secondary">Далее</a>
                            </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <img src={calendar_img} className="card-img-top"  />
                        <div className="card-body">
                            <h5 className="card-title">Календарь дивидендов дивидендных аристократов США</h5>
                            <p className="card-text">Узнайте, когда будут ближайшие выплаты дивидендов</p>
                            <a href="/calendar" className="btn btn-secondary">Далее</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <FooterComponent/>
    </main>)
}

export default HomeComponent;