import React, {Component} from "react";
import CalendarApi from "../api/CalendarApi";
import HomeHeader from "./HomeHeader";
import ErrorComponent from "./ErrorComponent";
import {LoaderComponent, withLoader} from "./LoaderComponent";
import FooterComponent from "./FooterComponent";


class CalendarDataTable extends Component {
    state = {
        calendar: []
    };

    constructor(props) {
        super(props);
        this.state = {calendar: this.props.data}
    }

    componentDidMount() {
        document.title = "Portfoliomanger - календарь событий дивидендных аристократов США"
    }

    render() {
        return this.state.calendar.map((calendarItem) =>
            <tr>
                <td className="text-center">{calendarItem.ticker}</td>
                <td className="text-center">{calendarItem.companyName}</td>
                <td className="text-center">{calendarItem.reportDate}</td>
                <td className="text-center">{calendarItem.upcomingDividendDate}</td>
                <td className="text-center">{calendarItem.estimate}</td>
                <td className="text-center">{calendarItem.currency}</td>
                <td className="text-center">
                    <a href={"stock/" + calendarItem.ticker} target="_blank" className="btn btn-secondary">Детали</a>
                </td>
            </tr>
        )
    }
}

const CalendarTableWithLoader = withLoader(CalendarDataTable, () => {
    return new CalendarApi().getDividendAristocratsCalendar()
});

export default class CalendarComponent extends Component {
    state = {
        error: null,
        loading: true
    }

    loaderHandler = (loading) => this.setState({loading: loading})
    errorHandler = (error) => this.setState({error: error})

    getErrorComponent() {
        return <ErrorComponent error={this.state.error}/>
    }

    getLoader() {
        return <LoaderComponent loading={this.state.loading}/>
    }

    render() {
        return (
            <div>
                <HomeHeader></HomeHeader>
                <div className="container mainComponentContainer">
                    <ErrorComponent error={this.state.error}/>
                    <h2 className="text-center">Календарь событий дивидендных аристократов США</h2>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                            <tr style={{
                                position: 'sticky', top: '0', 'zIndex': '1', background: '#ffffff',
                                'boxShadow': 'inset 0 0px 0 #000000, inset 0 -1px 0 #000000'
                            }}>
                                <th scope="col" className="text-center">Тикер</th>
                                <th scope="col" className="text-center">Название</th>
                                <th scope="col" className="text-center ">Дата отчета</th>
                                <th scope="col" className="text-center">Дата дивидендов</th>
                                <th scope="col" className="text-center">Прогноз прибыли на акцию</th>
                                <th scope="col" className="text-center">Валюта</th>
                                <th scope="col" className="text-center"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <CalendarTableWithLoader loaderHandler={this.loaderHandler}
                                                     errorHandler={this.errorHandler}/>
                            </tbody>
                        </table>
                    </div>
                    <div className="text-center">
                        {this.getLoader()}
                    </div>
                </div>
                <FooterComponent/>
            </div>
        );
    }
}