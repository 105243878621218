import React, {Component} from 'react';
import AutoCompleteComponent from "./AutoCompleteComponent";


export default class HomeHeader extends Component {
    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-md navbar-dark bg-dark">
                    <div className="container">
                        <a className="navbar-brand" href="/">PM</a>
                        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarCollapse">
                            <ul className="navbar-nav me-auto mb-2 mb-md-0">
                                <li className="nav-item active">
                                    <a className="nav-link" aria-current="page" href="/rating">Рейтинг</a>
                                </li>
                                <li className="nav-item active">
                                    <a className="nav-link" aria-current="page" href="/calendar">Календарь</a>
                                </li>
                            </ul>
                            <AutoCompleteComponent  placeholder = "Поиск компании"></AutoCompleteComponent>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
};