import React from 'react';
import {Route, Switch,} from "react-router-dom"

import HomeComponent from './component/HomeComponent';
import StockPageComponent from './component/StockPage';
import CalendarComponent from "./component/CalendarComponent";
import AristocratsRatingComponent from "./component/AristocratsRatingComponent";


//BlackTerminal — единый инструмент инвестора фондового рынка
//Отчетность компаний, мультипликаторы, фундаментальный анализ, котировки и графики акций, доходности облигаций, история дивидендных выплат
const AppRouter = () => {
    return (
        <Switch>
            <Route path='/rating' component={AristocratsRatingComponent}/>
            <Route path='/calendar' component={CalendarComponent}/>
            <Route path='/stock/:stockSymbol' component={StockPageComponent}/>
            <Route path='/' component={HomeComponent}/>
        </Switch>
    );
};

export default AppRouter;