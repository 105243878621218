import React, {Component} from 'react';
import ErrorComponent from "./ErrorComponent";

class LoaderComponent extends Component {
    render() {
        return (this.props.loading ? <div className="spinner-border" role="status"/> : <div/>);
    }
}

function withLoader(WrappedComponent, dataSource, updateCondition) {
    return class extends React.Component {
        state = {
            data: [],
            error: null,
            loading: true
        }

        componentDidUpdate(prevProps) {
            if (updateCondition && updateCondition(this.props, prevProps)) {
                this.setState({loading: true})
                if (this.props.loaderHandler) {
                    this.props.loaderHandler(true)
                }
                this.fetchData()
            }
        }

        componentDidMount() {
            this.fetchData()
        }

        fetchData = () => {
            dataSource(this.props).then(res => {
                this.setState({data: res.data})
                this.setState({loading: false})
                if (this.props.loaderHandler) {
                    this.props.loaderHandler(false)
                }
            }).catch(error => {
                console.log("Error with data")
                this.setState({error: error})
                this.setState({loading: false})
                if (this.props.errorHandler) {
                    this.props.errorHandler(error)
                }
                if (this.props.loaderHandler) {
                    this.props.loaderHandler(false)
                }
            })
        }

        render() {
            const {error, loading} = this.state;
            if (error && !this.props.errorHandler) {
                return <ErrorComponent error={this.state.error}/>
            }
            if (loading) {
                if (this.props.loaderHandler) {
                    return <div/>
                } else {
                    //TODO replace by component
                    return <div className="spinner-border" role="status"/>
                }
            } else {
                return <WrappedComponent data={this.state.data} {...this.props}/>;
            }
        }
    }
}

export {
    LoaderComponent,
    withLoader
}